import React from 'react';
import { EventArtist } from '../../interfaces';
import StyledButton from '../components/StyledButton';
import { checkHighestRated } from './checkHighestRated';
import { mapRatingColors } from './mapRatingColor';

export const mapEventArtistsDisplay = (
  items: EventArtist[],
  onArtistRateClick: (index: number) => void
) => {
  return items
    .filter(checkHighestRated)
    .map((item, i) => {
      const rating = item.artist.myrating.score;
      const bgColor = rating ? mapRatingColors(rating) : '#000';
      const borderColor = rating ? mapRatingColors(rating) : '#c8c8c8';
      const textColor = rating ? '#000' : '#c8c8c8'
      return {
        action: (
          <StyledButton
            block
            backgroundColor={bgColor}
            borderColor={borderColor}
            textColor={textColor}
            onClick={() => onArtistRateClick(i)}
          >
            {item.artist.myrating.text || 'Rate Artist'}
          </StyledButton>
        ),
        fields: [
          {
            displayKey: 'Name',
            isTitle: true,
            value: item.artist.name,
            class: 'freq-sho-bold freq-sho-list-title',
          },
          {
            displayKey: 'Genre',
            value: item.artist.genre,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Subgenre',
            value: item.artist.subgenre,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'City',
            value: item.artist.city,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'State',
            value: item.artist.state,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Country',
            value: item.artist.country,
            class: 'freq-sho-light',
          },
        ],
      };
    });
};
