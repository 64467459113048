import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { DataItem } from '../types';
import Loading from './Loading';

type Props = {
  items: DataItem[];
  isLoading?: boolean;
};
const DataList = ({ items, isLoading }: Props) => {

  useEffect(() => {
    if (isLoading) {
      window.scrollTo(0, 0)
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    return (() => {
      document.body.classList.remove('overflow-hidden')
    })
  }, [isLoading]);

  const noContent = !items?.length && !isLoading
  return (
    <>
      {isLoading && (
        <div className="data-list-spinner">
          <div style={{ paddingTop: 45 }}>
            <Loading />
          </div>
        </div>
      )}
      {!!noContent && !isLoading && (
        <div className="no-content">No results found...</div>
      )}
      <Container fluid className={isLoading ? 'mobile-loading-overlay pt-2' : 'pt-2'}>
        {items.map((item, i) => {
          const titleField = item.fields.find((field) => field.isTitle);
          const otherFields = item.fields.filter((field) => !field.isTitle);
          return (
            <Row className="py-1 my-1" key={i}>
              <Col>
                {titleField && (
                  <Row className={`${titleField.class} mb-1`}>
                    <Col className="mx-0 px-0">{titleField.value}</Col>
                  </Row>
                )}
                <Row className="border-bottom align-items-center justify-content-between px-0 pb-1">
                  <Col xs="6" className="pr-1">
                    {otherFields.map((field, i) => (
                      <Row key={i} className="mb-1">
                        <Col className="mx-0 px-0">
                          <span
                            className={`${!field.value && 'text-muted'
                              } mr-1 freq-sho-medium`}
                          >
                            {field.displayKey + ': '}
                          </span>
                          <span className={field.class}>{field.value}</span>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col
                    xs="6"
                    className="text-right small pl-3 pr-0"
                    style={{ marginTop: -40 }}
                  >
                    {item.action}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Container>
    </>

  );
};

export default DataList;
