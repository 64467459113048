import React, { useEffect } from 'react';
import { useBreakpoint } from '../hooks/useBreakpoint';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

type Props = {
	value?: string;
	options: string[];
	onChange(value: string): void;
	multiple?: boolean;
	variant?: string;
	width?: number;
	fontSize?: any;
};
const StyledSelect = ({ value, options, multiple, onChange, variant, width, fontSize }: Props) => {
	const breakpoint = useBreakpoint();

	return (
		<React.Fragment>
			{(breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') && (
				<Form.Control
					onChange={(e: any) => onChange(e.target.value)}
					value={value}
					as="select"
					custom
					style={{ borderRadius: 3 }}
					className="mt-2"
					multiple={multiple}
				>
					{options.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</Form.Control>
			)}
			{(breakpoint === 'lg' || breakpoint === 'xl') && (
				<Dropdown
					style={{ width: width || 150 }}
					alignRight
					id="dropdown-menu-align-right"
				>
					<Dropdown.Toggle
						style={{ borderRadius: 3, fontSize: fontSize || '.9rem' }}
						className="w-100 justify-content-between"
						variant={variant as any || "secondary"}
						id="dropdown-basic"
					>
						{value}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{options.map((option) => (
							<Dropdown.Item key={option} onClick={() => onChange(option)}>
								{option}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			)}
		</React.Fragment>
	);
};

export default StyledSelect;
