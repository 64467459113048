import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import * as yup from 'yup';
import StyledButton from '../components/StyledButton';
import StyledInput from '../components/StyledInput';
import { AuthContext } from '../state/AuthContext';

type FormValues = {
	username: string;
	password: string;
};

const initialValues: FormValues = {
	username: '',
	password: '',
};

const validation = yup.object().shape({
	username: yup.string().label('Username').required(),
	password: yup
		.string()
		.label('Password')
		.required()
		.min(2, 'Password is too short.'),
});

const Login = () => {
	const { signedIn, signIn } = useContext(AuthContext);
	const [authError, setAuthError] = useState(false);
	return (
		<Container fluid>
			<Row>
				<Card
					bg={'dark'}
					style={{ width: '90%', maxWidth: 500 }}
					className="p-4 mx-auto my-5"
				>
					<Card.Header className="px-5">
						<Image
							src={require('../../assets/images/freqsho-admin-logo.png')}
							fluid
						/>
					</Card.Header>
					<Formik
						initialValues={initialValues}
						onSubmit={async (values, actions) =>
							await signIn(values.username, values.password)
						}
						validationSchema={validation}
					>
						{(formikProps) => (
							<Form>
								<StyledInput
									label="Username"
									placeholder="johnsmith"
									type="text"
									formikProps={formikProps}
									formikKey="username"
								/>
								<StyledInput
									label="Password"
									placeholder="••••••••"
									type="password"
									formikProps={formikProps}
									formikKey="password"
								/>
								{formikProps.isSubmitting ? (
									<Row className="w-100 mx-0">
										<Spinner className="mx-auto mt-2" animation="border" />
									</Row>
								) : (
									<StyledButton
										className="w-100 mt-3"
										variant="outline-secondary"
										disabled={formikProps.isSubmitting}
										onClick={() => formikProps.handleSubmit()}
									>
										Login
									</StyledButton>
								)}
								{authError && (
									<Alert className="mt-3 text-center" variant="danger">
										Unable to authenticate. Please try again.
									</Alert>
								)}
							</Form>
						)}
					</Formik>
				</Card>
			</Row>
		</Container>
	);
};

export default Login;
