import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

type Props = {
	backgroundColor?: string;
	borderColor?: string;
	textColor?: string;
	children: React.ReactNode;
};
const StyledButton = ({
	backgroundColor,
	borderColor,
	textColor,
	children,
	...rest
}: Props & ButtonProps & React.HTMLAttributes<HTMLButtonElement>) => {
	const bgStyles = backgroundColor && {
		backgroundColor,
	};
	const borderStyles = borderColor && {
		borderColor
	}
	const textStyles = textColor && {
		color: textColor
	}
	return (
		<Button
			// style={ButtonStyles}
			style={{ ...ButtonStyles, ...bgStyles, ...borderStyles, ...textStyles }}
			className="freq-sho-medium text-uppercase fs-styled-button"
			{...rest}
		>
			{children}
		</Button>
	);
};

let ButtonStyles = {
	borderRadius: 3,
};

export default StyledButton;
