import moment from 'moment';
import React from 'react';
import { EventShow } from '../../interfaces';
import StyledButton from '../components/StyledButton';
import { checkHighestRated } from './checkHighestRated';
import { mapRatingColors } from './mapRatingColor';

export const mapEventShowsDisplay = (items: EventShow[], onArtistRateClick: (index: number) => void) => {
  return items
    .filter(checkHighestRated)
    .map((item, i) => {
      const rating = item.artist.myrating.score;
      const bgColor = rating ? mapRatingColors(rating) : '#000';
      const borderColor = rating ? mapRatingColors(rating) : '#c8c8c8';
      const textColor = rating ? '#000' : '#c8c8c8'
      return {
        action: (
          <StyledButton
            block
            backgroundColor={bgColor}
            borderColor={borderColor}
            textColor={textColor}
            onClick={() => onArtistRateClick(i)}
          >
            {item.artist.myrating.text || 'Rate Artist'}
          </StyledButton>
        ),
        fields: [
          {
            displayKey: 'Date',
            value: moment(item.start_time).format('M/DD/YY'),
            class: 'freq-sho-bold',
          },
          {
            displayKey: 'Start Time',
            value: moment(item.start_time).format('h:mm A'),
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Artist',
            value: item.artist.name,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Genre',
            value: item.artist.genre,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Subgenre',
            value: item.artist.subgenre,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Venue',
            value: item.venue?.name,
            class: 'freq-sho-light',
          },
          {
            displayKey: 'Address',
            value: item.venue?.location?.address,
            class: 'freq-sho-light',
          },
        ],
      };
    });
};
