import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { fetchEvents } from '../api';
import Loading from '../components/Loading';

type FSEvent = {
  id: number;
  name: string;
  start_date: Date;
  end_date: Date;
};

const ListEvents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<FSEvent[]>();

  useEffect(() => {
    async function getData() {
      const res = await fetchEvents();
      const data = res.data.results;
      setEvents(data);
      setIsLoading(false);
    }
    getData();
  }, []);

  return (
    <>
      <h1>Events</h1>
      {isLoading &&
        <div className="primary-loading">
          <Loading />
        </div>
      }
      {!isLoading && (
        <Container fluid className="px-0 mx-0">
          <div className="mb-3">
            {events?.map((event) => (
              <div key={event.id} className="my-2">
                <Link
                  style={{ fontSize: '1.3rem' }}
                  to={`/events/${event.id}?display=Artists`}
                >
                  {event.name}
                </Link>
              </div>
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default ListEvents;
