import { motion } from 'framer-motion';
import { stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import { FaFilter } from 'react-icons/fa';
import Pagination from 'react-js-pagination';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import {
  encodeQueryParams, NumberParam,
  StringParam,
  useQueryParams
} from 'use-query-params';
import { Artist } from '../../interfaces';
import { ArtistVideo } from '../../interfaces/AritstVideo';
import {
  fetchArtists, fetchArtistVideos, fetchGenres
} from '../api';
import DataList from '../components/DataList';
import DataTable from '../components/DataTable';
import Header from '../components/Header';
import Loading from '../components/Loading';
import RatingModal from '../components/RatingModal';
import StyledButton from '../components/StyledButton';
import StyledSwitch from '../components/StyledSwitch';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { UserArtistRatingsContext } from '../state/UserArtistRatings';
import { DataItemState } from '../types';
import { mapAllArtistsDisplay } from '../util/mapAllArtistsDisplay';

type MatchParams = {
  id: string;
};

const ListArtists = ({ match }: RouteComponentProps<MatchParams>) => {
  const [isLoading, setIsLoading] = useState(true);

  const [userArtistRatings, setUserArtistRatings] = useState();

  const [allArtists, setAllArtists] = useState();
  const [eventDisplayItems, setEventDisplayItems]: any[] = useState();

  const [genres, setGenres]: DataItemState<
    { id: number; name: string }[]
  > = useState();

  const [forceNextPage, setForceNextPage] = useState(false);

  const [
    artistBeingRated,
    setArtistBeingRated,
  ]: DataItemState<Artist> = useState();

  const [artistVideos, setArtistVideos]: DataItemState<
    ArtistVideo[]
  > = useState();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    display: StringParam,
    freqshorecommends: NumberParam,
    name: StringParam,
    freqgenres: StringParam,
    ratingIndex: NumberParam,
    order: StringParam,
  });

  const [resultCount, setResultCount]: DataItemState<number> = useState();

  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const breakpoint = useBreakpoint();

  // init
  useEffect(() => {
    async function initialize() {
      const { id } = match.params;
      const { display } = query;
      const data = await fetchArtists();
      const genres = await fetchGenres();
      setAllArtists(data.results);
      setResultCount(data.count);
      setGenres(genres);
      setIsLoading(false);

      const queryString = stringify(
        encodeQueryParams(
          {
            page: NumberParam,
            freqshorecommends: NumberParam,
            name: StringParam,
            order: StringParam,
          },
          query
        )
      );

      const displayFormattedItems = mapAllArtistsDisplay(
        (data.results as any) as Artist[],
        onArtistRateClick
      );

      setEventDisplayItems(displayFormattedItems);
    }

    initialize();
  }, []);

  // all query param changes except ratingIndex
  useEffect(() => {
    if (!query) return;

    async function update() {
      setIsLoading(true);
      const queryString = stringify(
        encodeQueryParams(
          {
            page: NumberParam,
            display: StringParam,
            freqshorecommends: NumberParam,
            name: StringParam,
            order: StringParam,
          },
          query
        )
      );
      const dataItems = await fetchArtists(queryString);
      const userRatings = dataItems.results.map((x) => {
        return { id: x.id, score: x.score };
      });
      setUserArtistRatings(userRatings);
      setAllArtists(dataItems.results);
      const displayFormattedItems = mapAllArtistsDisplay(
        (dataItems.results as any) as Artist[],
        onArtistRateClick
      );
      setEventDisplayItems(displayFormattedItems);
      setResultCount(dataItems.count);
      setIsLoading(false);
    }

    update(); // todo: come back to this
  }, [
    query.page,
    query.display,
    query.freqshorecommends,
    query.name,
    query.freqgenres,
    query.order,
  ]);

  // selecting an artist to rate
  useEffect(() => {
    if (query.ratingIndex === undefined || !allArtists) return;

    if (query.ratingIndex === undefined) {
      setArtistVideos(undefined);
      return;
    }
    async function getArtistData() {
      const index = query.ratingIndex as number;
      if (!(allArtists as any)[index]) return;
      const artist = (allArtists as any)[index];
      // const artistData = (await fetchArtist(artist.id));
      const artistVideos = (await fetchArtistVideos(artist.id)).data;
      setArtistBeingRated(artist);
      setArtistVideos(artistVideos.videos);
    }

    if (query.ratingIndex !== undefined) getArtistData();
  }, [query.ratingIndex, allArtists]);

  useEffect(() => {
    if (
      !userArtistRatings ||
      !allArtists ||
      !query ||
      query.ratingIndex !== undefined
    )
      return;
    async function update() {
      setIsLoading(true);
      const { id } = match.params;
      const { display } = query;
      const queryString = stringify(
        encodeQueryParams(
          {
            page: NumberParam,
            display: StringParam,
            freqshorecommends: NumberParam,
            name: StringParam,
            order: StringParam,
          },
          query
        )
      );

      const dataItems = await fetchArtists(queryString);
      setAllArtists(dataItems.results);
      const displayFormattedItems = mapAllArtistsDisplay(
        (dataItems.results as any) as Artist[],
        onArtistRateClick
      );
      setEventDisplayItems(displayFormattedItems);
      setIsLoading(false);
    }
    update();
  }, [query.ratingIndex]);

  const onArtistRateClick = (index: number) => {
    setQuery({ ratingIndex: index });
  };

  const onPreviousArtistClick = () => {
    if (!allArtists) return;
    const prevIndex = query.ratingIndex - 1;
    if (prevIndex < 0) {
      if (!query.page || query.page === 1) return;
      setQuery({
        ratingIndex: (allArtists as any).length - 1,
        page: (query.page || 1) - 1,
      });
    } else {
      setQuery({ ratingIndex: prevIndex });
    }
  };

  const onNextArtistClick = () => {
    if (!allArtists) return;
    const nextIndex = query.ratingIndex + 1;
    if (nextIndex > (allArtists as any).length - 1) {
      setQuery({ ratingIndex: 0, page: (query.page || 1) + 1 });
    } else {
      setQuery({ ratingIndex: nextIndex });
    }
  };

  if (!allArtists) {
    return (
      <div className="primary-loading">
        <Loading />
      </div>
    )
  }
  return (
    <div>
      <UserArtistRatingsContext.Provider
        value={[userArtistRatings, setUserArtistRatings]}
      >
        <RatingModal
          videos={artistVideos}
          ratingIndex={query.ratingIndex}
          setQuery={setQuery}
          artistBeingRated={artistBeingRated}
          onPrevious={onPreviousArtistClick}
          onNext={onNextArtistClick}
        />
        {allArtists && (
          <Header
            // filterMobile={
            // 	<FaFilter size={20} onClick={() => console.log('hello')} />
            // }
            filterDesktop={
              <React.Fragment>
                <StyledButton
                  className="align-items-center justify-content-center small mr-2"
                  variant="secondary"
                  onClick={() => setAreFiltersOpen((prevState) => !prevState)}
                >
                  <FaFilter size={20} />
                  <span className="my-0 py-0 ml-2">
                    {areFiltersOpen ? 'Hide' : 'View'} Filters
                  </span>
                </StyledButton>
                <Badge pill variant="secondary" className="ml-4">
                  <div className="px-2 py-1 m-0">{resultCount} Results</div>
                </Badge>
              </React.Fragment>
            }
            title="All Artists"
          />
        )}
        {(breakpoint === 'xs' ||
          breakpoint === 'sm' ||
          breakpoint === 'md' ||
          breakpoint === 'lg') && (
            <React.Fragment>
              <motion.div
                variants={MobileFilterVariants}
                animate={areFiltersOpen ? 'open' : 'closed'}
                style={{ height: areFiltersOpen ? 'auto' : 0 }}
                className={areFiltersOpen ? 'mt-5 mb-3' : ''}
              // initial={{x: '-150%' }}
              >
                <div>
                  <div className="pb-1">
                    <form>
                      <FormGroup role="form">
                        <FormControl
                          placeholder="Search by Artist"
                          type="text"
                          className="form-control"
                          value={query.name || ''}
                          onChange={(e) => {
                            const name = e.target.value;
                            if (!name || name === '') {
                              setQuery({ name: undefined, page: undefined });
                            } else {
                              setQuery({ name: e.target.value, page: undefined });
                            }
                          }}
                        />
                      </FormGroup>
                    </form>
                  </div>

                  <div className="pb-3">
                    {genres && (
                      <Select
                        placeholder="Search by Genre"
                        className="multi-select"
                        isMulti
                        // value={query?.freqgenres?.split(',').map((x) => {
                        // 	const clone = cloneDeep(genres);
                        // 	return clone.filter(
                        // 		(genre: any) => genre.id === parseInt(x, 10)
                        // 	);
                        // })}
                        onChange={(e: any) => {
                          const genresString = e
                            ?.map((genre: any) => genre.value)
                            .join(',');
                          setQuery({
                            freqgenres: genresString || undefined,
                            page: undefined,
                          });
                        }}
                        options={genres?.map((x) => {
                          return { label: x.name, value: x.id };
                        })}
                      />
                    )}
                  </div>
                  <div className="pb-3">
                    <StyledSwitch
                      label="FreqSho Recommends"
                      onChange={(val: boolean) =>
                        setQuery({
                          freqshorecommends: val ? 1 : undefined,
                          page: undefined,
                        })
                      }
                      checked={!!query.freqshorecommends}
                    />
                  </div>
                  <div className="pb-0">
                    <Button
                      onClick={() =>
                        setQuery({
                          page: undefined,
                          freqshorecommends: undefined,
                          freqgenres: undefined,
                          name: undefined,
                        })
                      }
                    >
                      Clear Filters
                    </Button>
                  </div>
                </div>
              </motion.div>

              {eventDisplayItems && <DataList items={eventDisplayItems} />}
            </React.Fragment>
          )}

        {breakpoint === 'xl' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <motion.div
              style={{
                visibility: areFiltersOpen ? 'visible' : 'hidden',
              }}
              variants={FilterVariants}
              initial={areFiltersOpen ? 'expanded' : 'collapsed'}
              animate={areFiltersOpen ? 'expanded' : 'collapsed'}
            >
              <div className="pr-4">
                <div className="pb-3">
                  <form>
                    <FormGroup role="form">
                      <FormControl
                        placeholder="Search by Artist"
                        type="text"
                        className="form-control"
                        value={query.name || ''}
                        onChange={(e) => {
                          const name = e.target.value;
                          if (!name || name === '') {
                            setQuery({ name: undefined, page: undefined });
                          } else {
                            setQuery({ name: e.target.value, page: undefined });
                          }
                        }}
                      />
                    </FormGroup>
                  </form>
                </div>

                <div className="pb-3">
                  {genres && (
                    <Select
                      placeholder="Search by Genre"
                      className="multi-select"
                      isMulti
                      // value={query?.freqgenres?.split(',').map((x) => {
                      // 	const clone = cloneDeep(genres);
                      // 	return clone.filter(
                      // 		(genre: any) => genre.id === parseInt(x, 10)
                      // 	);
                      // })}
                      onChange={(e: any) => {
                        const genresString = e
                          ?.map((genre: any) => genre.value)
                          .join(',');
                        setQuery({
                          freqgenres: genresString || undefined,
                          page: undefined,
                        });
                      }}
                      options={genres?.map((x) => {
                        return { label: x.name, value: x.id };
                      })}
                    />
                  )}
                </div>
                <div className="pb-3">
                  <StyledSwitch
                    label="FreqSho Recommends"
                    onChange={(val: boolean) =>
                      setQuery({
                        freqshorecommends: val ? 1 : undefined,
                        page: undefined,
                      })
                    }
                    checked={!!query.freqshorecommends}
                  />
                </div>
                <div className="pb-0">
                  <Button
                    onClick={() =>
                      setQuery({
                        page: undefined,
                        freqshorecommends: undefined,
                        freqgenres: undefined,
                        name: undefined,
                      })
                    }
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            </motion.div>

            {eventDisplayItems && (
              <DataTable items={eventDisplayItems} isLoading={isLoading} />
            )}
          </div>
        )}

        {resultCount && (
          <Container fluid className="mt-4">
            <Row>
              <Col>
                <Pagination
                  disabledClass="pagination-disabled"
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={query.page || 1}
                  itemsCountPerPage={20}
                  totalItemsCount={resultCount}
                  pageRangeDisplayed={5}
                  onChange={(page: number) => setQuery({ page })}
                />
              </Col>
            </Row>
          </Container>
        )}
      </UserArtistRatingsContext.Provider>
    </div>
  );
};

export default ListArtists;

const FilterVariants = {
  expanded: () => ({
    width: 600,
  }),
  collapsed: () => ({
    width: 0,
  }),
};

const MobileFilterVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-100%' },
};
