import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import { mapRatingColors } from '../util/mapRatingColor';
import StyledButton from './StyledButton';
import { UserArtistRatingsContext } from '../state/UserArtistRatings';
import { rateArtist, deleteArtistRating } from '../api';
import styled from 'styled-components';
import Marquee from 'react-fast-marquee';

const ThumbnailContainer = styled.div`
	cursor: pointer;
	&:hover {
		opacity: 0.75;
		border-color: #fff301;
	}
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top;
	@media (min-width: 1px) {
		width: 100px;
		height: 70px;
	}

	@media (min-width: 1200px) {
		width: 100%;
		height: 225px;
	}
`;

const RatingModal = ({
  ratingIndex,
  setQuery,
  artistBeingRated,
  onPrevious,
  onNext,
  videos,
}) => {
  const [userArtistRatings, setUserArtistRatings] = useContext(
    UserArtistRatingsContext
  );
  const [rating, setRating] = useState();

  const [activeVideoId, setActiveVideoId] = useState<string>();
  useEffect(() => {
    if (!videos || !videos.length) return;
    if (!activeVideoId || !videos?.find((x) => x._id === activeVideoId)) {
      setActiveVideoId(videos[0]._id);
    }
  }, [videos]);

  useEffect(() => {
    if (!userArtistRatings || !artistBeingRated) return;
    const currentRating = userArtistRatings.find(
      (x) => x.id === artistBeingRated.id
    );
    if (currentRating && currentRating.score) {
      setRating(currentRating.score);
    } else {
      setRating(null);
    }
  }, [userArtistRatings, artistBeingRated]);

  // useEffect(() => {
  //   if (!activeVideoId) return
  //   if (document.querySelector('.active-thumbnail-video')) {
  //     //@ts-ignore
  //     document.querySelector('.active-thumbnail-video').scrollIntoViewIfNeeded()
  //   }
  // }, [activeVideoId])

  const onVideoThumbnailClick = (videoId: string) => {
    setActiveVideoId(videoId);
  };

  const onRatingClick = (score: number) => {
    const firstTimeRating = rating === null;
    if (rating === score) {
      deleteArtistRating(artistBeingRated.id);
      const index = userArtistRatings.findIndex(
        (x) => x.id === artistBeingRated.id
      );
      const ratings = [...userArtistRatings];
      ratings[index].score = undefined;
      setUserArtistRatings(ratings);
    } else {
      rateArtist(artistBeingRated.id, score, firstTimeRating);
      const index = userArtistRatings.findIndex(
        (x) => x.id === artistBeingRated.id
      );
      const ratings = [...userArtistRatings];
      ratings[index].score = score;
      setUserArtistRatings(ratings);
    }
  };

  const formatEmbedUrl = (url: string) => {
    const ap = 1;
    return (
      url.replace('watch?v=', 'embed/').replace('http:', 'https:') +
      `?autoplay=${ap}&rel=0`
    );
  };

  // const videos?.find((x) => x._id === activeVideoId) = videos?.find((x) => x._id === activeVideoId);
  return (
    <Modal
      show={ratingIndex !== undefined}
      backdrop="static"
      onHide={() => setQuery({ ratingIndex: undefined })}
      size="xl"
      scrollable
      dialogClassName="fs-modal"
      centered
    >
      <Modal.Header className="fs-rating-modal-header" closeButton>
        <>
          {artistBeingRated && (
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Modal.Title>
                    <h1>
                      <a href={artistBeingRated.hyperlink} target="_blank">
                        {artistBeingRated.name}
                      </a>
                    </h1>
                  </Modal.Title>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div>
                    <div>
                      <span className="font-weight-bold">
                        Genres: {artistBeingRated.genre},{' '}
                        {artistBeingRated.subgenre}
                      </span>
                    </div>
                    <div>
                      <span className="font-weight-bold">Hometown: </span>
                      {artistBeingRated.city && `${artistBeingRated.city}`}
                      {artistBeingRated.state && `, ${artistBeingRated.state}`}
                      {artistBeingRated.country &&
                        ` ${artistBeingRated.country}`}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-start align-items-xl-center px-0 px-xl-3">
        <Container
          style={{ maxWidth: '100%' }}
          className="mx-0 w-100 d-flex justify-content-center align-items-center"
        >
          <Row className="h-100 w-100">
            <Col className="h-100 " xs={12} xl={9}>
              <Row className="h-100 mb-4 mb-xl-0">
                <Col
                  xs={{ order: 2, span: 12 }}
                  xl={{ order: 1, span: 3 }}
                  style={{
                    position: 'relative',
                    top: 0,
                  }}
                  className="mx-0 fs-ratings-video-select h-100"
                >
                  {videos?.map((video) => {
                    const isVideoActive: boolean = activeVideoId === video._id;
                    return (
                      <div
                        key={video._id}
                        className="px-2 py-0 px-xl-0 py-xl-2"
                      >
                        <ThumbnailContainer
                          className={`d-flex justify-content-start border overflow-hidden ${isVideoActive ? 'active-thumbnail-video' : 'inactive-thumbnail-video'
                            }`}
                          style={{
                            backgroundImage: `url(${video.thumbnailUrl.replace('default', 'hqdefault')})`,
                          }}
                          onClick={() => onVideoThumbnailClick(video._id)}
                        >
                          <div className="d-none d-xl-flex" style={{
                            position: 'relative',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}>
                            <Marquee
                              loop={0}
                              speed={30}
                              gradient={false}
                              play={isVideoActive}
                              style={{
                                marginTop: '75%',
                                padding: '25px 5px',
                                backgroundColor: isVideoActive ? '#FFF301' : '#323232',
                              }}
                            >
                              <h6
                                className="p-1 m-0"
                                style={{
                                  backgroundColor: isVideoActive ? '#FFF301' : '#323232',
                                  color: isVideoActive ? 'black' : '#e9e8e8',
                                  fontWeight: 100,
                                  whiteSpace: 'nowrap',
                                  fontFamily: 'Futura-Light'
                                }}
                              >
                                {video.title}
                              </h6>
                            </Marquee>
                            {/* </div> */}

                          </div>
                        </ThumbnailContainer>
                      </div>
                    );
                  })}
                </Col>
                <Col
                  xs={{ order: 1, span: 12 }}
                  xl={{ order: 2, span: 9 }}
                  className="d-flex justify-content-center align-items-center fs-ratings-video-player"
                >
                  {videos?.length === 0 && <div>No videos found</div>}

                  {videos?.find((x) => x._id === activeVideoId) && (
                    <iframe
                      className="fs-ratings-video-player"
                      title={'video'}
                      width="720"
                      height="450"
                      src={formatEmbedUrl(
                        videos?.find((x) => x._id === activeVideoId).videoUrl
                      )}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ></iframe>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              className="h-100 text-center d-flex flex-column justify-content-between px-0"
              xs={12}
              xl={3}
            >
              <div>
                <h4 className="ml-xl-4 mb-xl-3">
                  {Cookies.get('username')}'s Rating
                </h4>
                <div className="w-100 pl-xl-4 align-self-end">
                  <StyledButton
                    onClick={() => onRatingClick(5)}
                    backgroundColor={rating === 5 ? mapRatingColors(5) : '#000'}
                    borderColor={rating === 5 ? mapRatingColors(5) : '#fff'}
                    textColor={rating === 5 ? '#000' : '#fff'}
                    className="text-uppercase font-weight-bold d-block w-100 mb-2 mb-xl-3"
                  >
                    Freq Yeah!
                  </StyledButton>
                  <StyledButton
                    onClick={() => onRatingClick(4)}
                    backgroundColor={rating === 4 ? mapRatingColors(4) : '#000'}
                    borderColor={rating === 4 ? mapRatingColors(4) : '#fff'}
                    textColor={rating === 4 ? '#000' : '#fff'}
                    className="text-uppercase font-weight-bold d-block w-100 mb-2 mb-xl-3"
                  >
                    Cool. I'm In.
                  </StyledButton>
                  <StyledButton
                    onClick={() => onRatingClick(3)}
                    backgroundColor={rating === 3 ? mapRatingColors(3) : '#000'}
                    borderColor={rating === 3 ? mapRatingColors(3) : '#fff'}
                    textColor={rating === 3 ? '#000' : '#fff'}
                    className="text-uppercase font-weight-bold d-block w-100 mb-2 mb-xl-3"
                  >
                    Curious (But Not Sold).
                  </StyledButton>
                  <StyledButton
                    onClick={() => onRatingClick(2)}
                    backgroundColor={rating === 2 ? mapRatingColors(2) : '#000'}
                    borderColor={rating === 2 ? mapRatingColors(2) : '#fff'}
                    textColor={rating === 2 ? '#000' : '#fff'}
                    className="text-uppercase font-weight-bold d-block w-100 mb-2 mb-xl-3"
                  >
                    Not Really My Jam.
                  </StyledButton>
                  <StyledButton
                    onClick={() => onRatingClick(1)}
                    backgroundColor={rating === 1 ? mapRatingColors(1) : '#000'}
                    borderColor={rating === 1 ? mapRatingColors(1) : '#fff'}
                    textColor={rating === 1 ? '#000' : '#fff'}
                    className="text-uppercase font-weight-bold d-block w-100 mb-2 mb-xl-3"
                  >
                    No Thank You.
                  </StyledButton>
                </div>
              </div>
              <div className="pl-xl-4">
                <Row>
                  <Col>
                    <StyledButton
                      onClick={onPrevious}
                      backgroundColor="#b1b1b1"
                      borderColor="#a3a3a3"
                      textColor="#000"
                      className="text-uppercase font-weight-bold d-block w-100 mb-3 d-flex justify-content-center align-items-center"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 19L8 12L15 5"
                          stroke="#000"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span style={{ marginTop: '.12rem' }} className="ml-3">
                        Previous
                      </span>
                    </StyledButton>
                  </Col>
                  <Col>
                    <StyledButton
                      onClick={onNext}
                      backgroundColor="#b1b1b1"
                      borderColor="#a3a3a3"
                      textColor="#000"
                      className="text-uppercase font-weight-bold d-block w-100 mb-3 d-flex justify-content-center align-items-center"
                    >
                      <span style={{ marginTop: '.12rem' }} className="mr-3">
                        Next
                      </span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 5L16 12L9 19"
                          stroke="#000"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </StyledButton>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div></div>
      </Modal.Body>
    </Modal>
  );
};

export default RatingModal;
