import Cookies from 'js-cookie';
import React, { createContext, useEffect, useState } from 'react';
import { fetchToken } from '../api';
import { useCookie } from '../hooks/useCookie';

export const AuthContext = createContext({
	checkedAuth: false,
	signedIn: false,
	signIn: () => {},
	signOut: () => {},
} as {
	checkedAuth: boolean;
	signedIn: boolean;
	signIn: any;
	signOut: any;
});

export const AuthProvider = ({ children }: any) => {
	const [checkedAuth, setCheckedAuth] = useState(false);
	const [signedIn, setSignedIn] = useState(false);
	const [usernameCookie, setUsernameCookie] = useCookie('username');
	const [tokenCookie, setTokenCookie] = useCookie('token');
	useEffect(() => {
		if (usernameCookie && tokenCookie) {
			setSignedIn(true);
		}
		setCheckedAuth(true);
	}, []);

	const signIn = async (username: string, password: string) => {
		const res = await fetchToken(username, password);
		const token = res.data.token;
		setUsernameCookie(username);
		setTokenCookie(token);
		setSignedIn(true);
	};
	const signOut = () => setSignedIn(false);

	return (
		<AuthContext.Provider value={{ checkedAuth, signedIn, signIn, signOut }}>
			{children}
		</AuthContext.Provider>
	);
};
