import axios from 'axios';
import Cookies from 'js-cookie';
import { Artist } from '../../interfaces';
import { ArtistVideo } from '../../interfaces/AritstVideo';

const apiBasePath = process.env.REACT_APP_API_BASE_URL;

// const CancelToken = axios.CancelToken;
// export const source = CancelToken.source();

const instance = axios.create();

instance.interceptors.request.use((config) => {
	const token = Cookies.get('token');
	config.headers.Accept = 'application/json';
	config.headers.Authorization = `Token ${token}`;
	return config;
});


export const fetchArtists = async (query?: string) => {
	const responses = await axios.all([
		instance.get(`${apiBasePath}/genres`),
		instance.get(`${apiBasePath}/artists?${query}`)
	])
	const [genres, artists] = responses.map((res) => res.data);
	const genreMap: { [id: number]: string } = {};
	genres.results.forEach(
		(genre: { id: number; name: string }) => (genreMap[genre.id] = genre.name)
	);

	artists.results.forEach((artist) => {
		artist.genre = genreMap[artist.genre]
	})
	return artists;
}

export const fetchArtist = async (artistId: number) => {
	const responses = await axios.all([
		instance.get(`${apiBasePath}/genres`),
		instance.get<Artist>(`${apiBasePath}/artists/${artistId}`)
	]);
	const [genres, artist] = responses.map((res) => res.data);

	const genreMap: { [id: number]: string } = {};
	genres.results.forEach(
		(genre: { id: number; name: string }) => (genreMap[genre.id] = genre.name)
	);
	artist.genre = genreMap[artist.genre as number];
	return artist
};

export const fetchArtistVideos = (artistId: number) => {
	return instance.get<{ videos: ArtistVideo[] }>(
		`${apiBasePath}/artists/${artistId}/videos`
	);
};

export const rateArtist = (artistId: number, score: number, firstTimeRating?: boolean) => {
	if (firstTimeRating) {
		const body = {
			"band": artistId,
			"rating": score,
		}
		return instance.post(`${apiBasePath}/ratings/user/artists`, body)
	} else {
		return instance.patch(`${apiBasePath}/ratings/user/artists/${artistId}/${score}`)
	}
}

export const deleteArtistRating = (artistId: number) => {
	return instance.delete(`${apiBasePath}/ratings/user/artists/${artistId}`)
}
