import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const useCookie = (key: string) => {
	const initial = Cookies.get(key);
	const [cookie, setStateCookie] = useState(initial);

	useEffect(() => {
		if (cookie) {
			Cookies.set(key, cookie as string);
		}
	}, [cookie, key]);

	const setCookie = async (value: string) => Cookies.set(key, value);

	return [cookie, setCookie] as [string, (value: string) => void];
};
