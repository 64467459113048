import React, { useEffect, useRef } from 'react';
import Table, { TableProps } from 'react-bootstrap/Table';
import { DataItem } from '../types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from './Loading';

type Props = {
  items: DataItem[];
  isLoading?: boolean;
};
const DataTable = ({
  items,
  isLoading,
  ...rest
}: Props & TableProps & React.HTMLAttributes<HTMLTableElement>) => {
  const refContainer = useRef(null) as any;

  useEffect(() => {
    if (!isLoading) return
    document.querySelector('.freq-sho-data-table').scrollTop = 0
  }, [isLoading]);

  const noContent = !items?.length && !isLoading
  return (
    <>
      <div className={`freq-sho-data-table w-100 ${isLoading ? 'pointer-events-none' : 'pointer-events-auto'}`} ref={refContainer}>
        {isLoading && (
          <>
            <Container fluid>
              <Row className="w-100 justify-content-center align-items-center">
                <Col className="loading-overlay">
                  <Loading />
                </Col>
              </Row>
            </Container>
          </>

        )}
        {!!noContent && (
          <div className="no-content">No results found...</div>
        )}
        {!!items?.length && (
          <Table striped {...rest}>
            <thead>
              <tr>
                {items[0].fields.map((field, i) => (
                  <th key={i} className="freq-sho-medium">
                    {field.displayKey}
                  </th>
                ))}
                <th style={{ width: 200 }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => (
                <tr key={i}>
                  {item.fields.map((field, i) => (
                    <td key={i}>{field.value}</td>
                  ))}
                  <td style={{ width: '250px' }}>{item.action}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default DataTable;
