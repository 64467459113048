import React, { useContext } from 'react';
import styles from './TopNavDesktop.module.css';
import { Link, NavLink } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import Cookies from 'js-cookie';
import StyledSelect from '../../../v2/components/StyledSelect';
import { AuthContext } from '../../../v2/state/AuthContext';

const TopNavDesktop = () => {
	const { signOut } = useContext(AuthContext);
	const username = Cookies.get('username');
	return (
		<div className={styles.container}>
			<NavLink to="/">
				<img
					className={styles.logo}
					src={require('../../../assets/images/freqsho-admin-logo.png')}
					alt="freak show logo"
				/>
			</NavLink>
			<div className={styles.links}>
				<div className="fs-desktop-nav-items">

					<Link to={'/events'} className="mr-3">Events</Link>
					<Link to={'/artists'}>Artists</Link>
					<StyledSelect
						value={username}
						options={['Sign Out']}
						onChange={signOut}
						variant="none"
						width={90}
						fontSize="1.3rem"
					/>
					{/* <Link to={'/'}>
						{username}
						<FiChevronDown className="ml-1" />
					</Link> */}
				</div>

			</div>
		</div>
	);

}

export default TopNavDesktop;
