export const mapRatingColors = (rating?: number) => {
	if (rating === 1) {
		return '#ff0000';
	} else if (rating === 2) {
		return '#ff9904';
	} else if (rating === 3) {
		return '#fdff05';
	} else if (rating === 4) {
		return '#00ffff';
	} else if (rating === 5) {
		return '#00ff00';
	}
};
