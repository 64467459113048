import React, { CSSProperties, useState, useContext } from 'react';
// @ts-ignore
import HamburgerMenu from 'react-hamburger-menu';
import { Link, NavLink } from 'react-router-dom';
import styles from './TopNavMobile.module.css';
import Cookies from 'js-cookie';
import { AuthContext } from '../../../v2/state/AuthContext';

type Props = {};
type State = {
    isOpen: boolean;
};
const TopNavMobile = () => {
    const username = Cookies.get('username');
    const [isOpen, setIsOpen] = useState(false)
    const { signOut } = useContext(AuthContext);

    const sideBarStyle = {
        transform: isOpen ? 'translateX(0)' : 'translateX(-130%)'
    } as CSSProperties;
    return (
        <div>
            <div className={styles.sidebarContainer} style={sideBarStyle}>
                <Link className={styles.link} onClick={() => setIsOpen(false)} to={'/events'}>Events</Link>
                <Link className={styles.link} onClick={() => setIsOpen(false)} to={'/artists'}>Artists</Link>
                <Link className={styles.link} onClick={() => {
                    setIsOpen(false)
                    signOut()
                }} to={'#'}>Sign out {username}</Link>
            </div>

            <div className={styles.topBar}>
                <NavLink to="/">
                    <img className={styles.logo} src={require('../../../assets/images/freqsho-admin-logo.png')} alt="freak show logo" />
                </NavLink>
                <div className={styles.menuIcon}>
                    <HamburgerMenu
                        isOpen={isOpen}
                        menuClicked={() => setIsOpen(prev => !prev)}
                        width={24}
                        height={22}
                        strokeWidth={1}
                        rotate={0}
                        color='#fff'
                        borderRadius={0}
                        animationDuration={0.5}
                    />
                </div>
            </div>
        </div>
    )

}

export default TopNavMobile;