import React from 'react';
import { Artist } from '../../interfaces';
import StyledButton from '../components/StyledButton';
import { mapRatingColors } from './mapRatingColor';

export const mapAllArtistsDisplay = (
    items: Artist[],
    onArtistRateClick: (index: number) => void
) => {
    return items.map((item, i) => {
        const rating = item.myrating.score;
        const bgColor = rating ? mapRatingColors(rating) : '#000';
        const borderColor = rating ? mapRatingColors(rating) : '#c8c8c8';
        const textColor = rating ? '#000' : '#c8c8c8'
        return {
            action: (
                <StyledButton
                    block
                    backgroundColor={bgColor}
                    borderColor={borderColor}
                    textColor={textColor}
                    onClick={() => onArtistRateClick(i)}
                >
                    {item.myrating.text || 'Rate Artist'}
                </StyledButton>
            ),
            fields: [
                {
                    displayKey: 'Name',
                    isTitle: true,
                    value: item.name,
                    class: 'freq-sho-bold freq-sho-list-title',
                },
                {
                    displayKey: 'Genre',
                    value: item.genre,
                    class: 'freq-sho-light',
                },
                {
                    displayKey: 'Subgenre',
                    value: item.subgenre,
                    class: 'freq-sho-light',
                },
                {
                    displayKey: 'City',
                    value: item.city,
                    class: 'freq-sho-light',
                },
                {
                    displayKey: 'State',
                    value: item.state,
                    class: 'freq-sho-light',
                },
                {
                    displayKey: 'Country',
                    value: item.country,
                    class: 'freq-sho-light',
                },
            ],
        };
    });
};
