import axios from 'axios';
import Cookies from 'js-cookie';
import { EventArtist } from '../../interfaces';

const apiBasePath = process.env.REACT_APP_API_BASE_URL;

// const CancelToken = axios.CancelToken;
// export const source = CancelToken.source();

const instance = axios.create();

instance.interceptors.request.use((config) => {
	const token = Cookies.get('token');
	config.headers.Accept = 'application/json';
	config.headers.Authorization = `Token ${token}`;
	return config;
});

export const fetchEvents = () => {
	return instance.get(`${apiBasePath}/events`);
};

export const fetchEvent = async (id: string) => {
	const res = await instance.get(`${apiBasePath}/events/${id}`);
	return res.data.results[0];
};

export const fetchEventArtists = async (id: string, query?: string) => {
	const responses = await axios.all([
		instance.get(`${apiBasePath}/genres`),
		instance.get(`${apiBasePath}/events/${id}/artists?${query}`),
	]);
	const [genres, eventArtists] = responses.map((res) => res.data);
	const genreMap: { [id: number]: string } = {};
	genres.results.forEach(
		(genre: { id: number; name: string }) => (genreMap[genre.id] = genre.name)
	);
	const results = eventArtists.results.map((eventArtist: EventArtist) => {
		if (eventArtist.artist.genre) {
			eventArtist.artist.genre = genreMap[eventArtist.artist.genre as number];
		}
		return eventArtist;
	});
	return { results, count: eventArtists.count };
};

export const fetchEventShows = async (id: string, query?: string) => {
	const responses = await axios.all([
		instance.get(`${apiBasePath}/genres`),
		instance.get(`${apiBasePath}/events/${id}/shows?${query}`),
	]);
	const [genres, eventShows] = responses.map((res) => res.data);
	const genreMap: { [id: number]: string } = {};
	genres.results.forEach(
		(genre: { id: number; name: string }) => (genreMap[genre.id] = genre.name)
	);
	const results = eventShows.results.map((eventShow: EventArtist) => {
		if (eventShow.artist.genre) {
			eventShow.artist.genre = genreMap[eventShow.artist.genre as number];
		}
		return eventShow;
	});
	return { results, count: eventShows.count };
};

export const fetchGenres = async () => {
	const response = await instance.get(`${apiBasePath}/freqgenres`);
	return response.data;
};
