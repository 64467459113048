import React from 'react';
import Switch from 'react-switch';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

type Props = {
	label: string;
	checked: boolean;
	onChange(e: any): void;
};
const StyledSwitch = ({ label, checked, onChange }: Props) => {
	return (
		<Container className="x-3">
			<Row className="align-items-center">
				<label className="d-flex align-items-center">
					<Switch
						onColor="#fff301"
						checkedIcon={false}
						uncheckedIcon={false}
						className="mr-2 align-self-center"
						onChange={(e) => onChange(e)}
						checked={checked}
					/>
					<span className="m-0 p-0 fre-sho-medium">{label}</span>
				</label>
			</Row>
		</Container>
	);
};

export default StyledSwitch;
