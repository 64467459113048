import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
  return (
    <div className="text-center w-100 justify-content-center align-items-center">
      <Spinner animation="border" />
    </div>
  );
};

export default Loading;
