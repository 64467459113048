import { FormikProps } from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';

// TODO: validation

type Props = {
	label?: string;
	placeholder: string;
	required?: boolean;
	type: string; // todo: stronger typing
	formikProps?: FormikProps<any>; // todo: typing
	formikKey?: string;
};

const StyledInput = ({
	label,
	placeholder,
	type,
	formikProps,
	formikKey,
}: Props) => {
	return (
		<Form.Group controlId={formikKey}>
			{label && <Form.Label>{label}</Form.Label>}
			<Form.Control
				type={type}
				placeholder={placeholder}
				value={formikProps && formikKey && formikProps.values[formikKey]}
				onChange={
					formikProps && formikKey
						? formikProps.handleChange(formikKey)
						: () =>
								console.log(
									'Please provide formikProps and formikKey in addition to an onChange method...'
								)
				}
			/>
		</Form.Group>
	);
};

export default StyledInput;
