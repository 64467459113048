import React from 'react';
import TopNavDesktop from './TopNavDesktop/TopNavDesktop';
import TopNavMobile from './TopNavMobile/TopNavMobile';

type Props = {};
export default class TopNav extends React.Component<Props> {
	render() {
		return (
			<>
				<div className="d-block d-lg-none">
					<TopNavMobile />
				</div>
				<div className="d-none d-lg-block">
					<TopNavDesktop />
				</div>
			</>
		);
	}
}
