import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useBreakpoint } from '../hooks/useBreakpoint';

const Header = ({
	title,
	filterMobile,
	filterDesktop,
	select,
}: {
	title: string;
	filterMobile?: React.ReactNode;
	filterDesktop?: React.ReactNode;
	select?: React.ReactNode;
}) => {
	const breakpoint = useBreakpoint();
	return (
		<div>
			<Container fluid className="px-0 mx-0 mb-0 mb-lg-4">
				<Row className="align-items-end justify-content-between">
					<Col xs="12" md="auto" className="justify-content-between">
						<Row className="align-items-center justify-content-between mb-1 mb-md-0">
							<Col xs="auto" md="12">
								<h1 className="m-0 p-0 mb-3 mb-lg-4">{title}</h1>
							</Col>
							{/* {breakpoint === 'xs' && (
								<Col xs="auto" md="12" className="text-right text-md-left">
									{filterMobile}
								</Col>
							)} */}
							{(breakpoint === 'xs' ||
								breakpoint === 'sm' ||
								breakpoint === 'md' ||
								breakpoint === 'lg' ||
								breakpoint === 'xl') && (
									<Col xs="auto" md="12" className="text-right text-md-left">
										{filterDesktop}
									</Col>
								)}
						</Row>
					</Col>
					<Col xs="12" md="auto">
						{select}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Header;
