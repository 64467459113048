import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styles from './App.module.css';
import TopNav from './components/TopNav/TopNav';
//v2
import DisplayEvent from './v2/screens/DisplayEvent';
import { AuthProvider } from './v2/state/AuthContext';
import AppContainer from './v2/components/AppContainer';
import ListEvents from './v2/screens/ListEvents';
import ListArtists from './v2/screens/ListArtists';
import { QueryParamProvider } from 'use-query-params';

const App = () => {
	return (
		<AuthProvider>
			<AppContainer>
				<div className={styles.container}>
					<Router>
						<TopNav />
						<QueryParamProvider ReactRouterRoute={Route}>
							<div className="mt-5 mt-lg-3">
								<Route exact path="/" component={ListEvents} />
								<Route exact path="/events" component={ListEvents} />
								<Route exact path="/events/:id" component={DisplayEvent} />
								<Route exact path="/artists" component={ListArtists} />
								{/* <Route exact path="/artists/:id" component={ArtistDetails} />
									<Route exact path="/my-ratings/" component={MyRatings} /> */}
							</div>
						</QueryParamProvider>
					</Router>
				</div>
			</AppContainer>
		</AuthProvider>
	);
};

export default App;
