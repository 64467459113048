import React, { useContext, FunctionComponent } from 'react';
import { AuthContext } from '../state/AuthContext';
import Loading from './Loading';
import Login from '../screens/Login';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AppContainer = ({ children }: { children: React.ReactNode }) => {
  const { signedIn, checkedAuth } = useContext(AuthContext);

  return (
    <React.Fragment>
      {!checkedAuth && (
        <div className="primary-loading">
          <Loading />
        </div>
      )}
      {checkedAuth && !signedIn && <Login />}
      {checkedAuth && signedIn && children}
    </React.Fragment>
  );
};

export default AppContainer;
